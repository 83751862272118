import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// redux
import { getAllSystemComponent } from "../../features/systemComponentSlice";
// config
import { permission } from "../../@core/data/permission";
// MUI
import {
    Box,
    Card,
    CardContent,
    Button,
} from "@mui/material";
// components
import ActionMenu from "../../components/actionmenu";
import Table from "../../components/table/Table";
import { iconSet } from "../../@core/data/icons";
import { ToastSuccess, ToastError } from "../../components/Toast";
import { CircleLoading } from "../../components/loadingCircle";
import ConfirmDialog from "src/components/ConfirmDialog";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import SystemComponentFormDialog from "../system-info/components/SystemComponentFormDialog";

// ----------------------------------------------------------------------

//let adminOptions = ["Edit", "Delete"]; // removing delete option until we have soft deletes
let adminOptions = ["Edit"];

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "", label: "Components" },
];

const DEFAULT_COMPONENT_DIALOG_STATE = { 
    open: false, 
    systemComponent: null, 
    systemInfoId: null, 
    requireNewComponent: true 
};

// ----------------------------------------------------------------------

export default function SystemComponentPage() {
    const dispatch = useDispatch();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const { roles } = useSelector((state) => state.login);
    const { loading, systemComponents } = useSelector((state) => state.systemComponent);
    const [deleteConfirm, setDeleteConfirm] = useState({ open: false, systemComponentSK: null });
    const [systemComponentFormDialog, setSystemComponentFormDialog] = useState(DEFAULT_COMPONENT_DIALOG_STATE);

    const canEditSystemComponent = roles.userCompanyRolePermissionInfo.some(
        (rolscheck) =>
            rolscheck.permissionName === permission.component && rolscheck.isWrite
    ) || isSuperAdmin;

    let hideColumn = canEditSystemComponent ? ['id', 'systemInfoId'] : ['id', 'systemInfoId', 'action'];

    const COLUMNS = [
        {
            Header: "ID",
            accessor: "id",
            Cell: ({ row }) => (
                <div>
                    <Link
                        style={{ textAlign: "left" }}
                        to={`/components/form?id=${row.values.systemComponentSK}`}
                    >
                        {row.values.systemComponentID}
                    </Link>
                </div>
            ),
            show: false,
        },
        {
            Header: "Type",
            accessor: "type",
            Cell: ({ row }) => (
                <div>{row.values.type ? row.values.type : "-"}</div>
            ),
        },
        {
            Header: "Make",
            accessor: "make",
            Cell: ({ row }) => (
                <div>{row.values.make ? row.values.make : "-"}</div>
            ),
        },
        {
            Header: "Model",
            accessor: "model",
            Cell: ({ row }) => (
                <div>{row.values.model ? row.values.model : "-"}</div>
            ),
        },
        {
            Header: "Serial Number",
            accessor: "serialNumber",
            Cell: ({ row }) => (
                <div>{row.values.serialNumber ? row.values.serialNumber : "-"}</div>
            ),
        },
        {
            Header: "System",
            accessor: "systemInfoName",
            Cell: ({ row }) => {
                return (canEditSystemComponent && row.values.systemInfoId) ? (
                    <div>
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/systems/form?id=${row.values.systemInfoId}`}
                        >
                            {row.values.systemInfoName !== null ? row.values.systemInfoName : "-"}
                        </Link>
                    </div>
                ) : (
                    <div>-</div>
                )
            }
        },
        {
            Header: "systemInfoId",
            accessor: "systemInfoId",
            show: false,
        },
        {
            Header: "Action",
            accessor: 'action',
            Cell: ({ row }) => (
                <Box>
                    {canEditSystemComponent && (
                        <ActionMenu
                            key="action-menu"
                            options={adminOptions}
                            handleMenuClick={(option) =>
                                handleItemMenuClick(row, option)
                            }
                        />
                    )}
                </Box>
            ),
        },
    ];

    const onCreateClick = () => {
        setSystemComponentFormDialog({ open: true, systemComponent: null, systemInfoId: null, requireNewComponent: true });
    };

    useEffect(() => {
        if (isSuperAdmin && adminSelectedCompany?.companyId) {
            const data = { companyId: adminSelectedCompany.companyId };
            dispatch(getAllSystemComponent(data));
        } else {
            dispatch(getAllSystemComponent());
        }
    }, [dispatch, adminSelectedCompany]);

    const handleItemMenuClick = (row, option) => {
        if (option === "Edit") {
            openSystemComponentFormDialog(row.values);
        } else if (option === "Delete") {
            onDeleteClick(row.id);
        }
    };

    const deleteSystemComponent = async (id) => {
        let headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");
        headers.append("Origin", "http://localhost:3000");
        const token = localStorage.getItem("token");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const response = await fetch(
            `${process.env.REACT_APP_APIURL}/SystemComponent/DeleteSystemComponent?systemComponentId=${id}&userId=${loginRes?.userId}`,
            config
        );

        if (response.ok) {
            const data = await response.json();

            if (data.messageKey && data.messageKey === "success") {
                ToastSuccess("Component deleted!");
                dispatch(getAllSystemComponent());
            } else if (data.messageKey === "failure") {
                ToastError("An unknown error occurred");
            }
        } else {
            throw new Error(`Request failed with status ${response.status}`);
        }
    };

    const closeDeleteConfirm = (confirm) => {
        if (confirm) {
            deleteSystemComponent(deleteConfirm.id);
        }

        setDeleteConfirm({ open: false, id: null });
    }

    const onDeleteClick = (id) => {
        setDeleteConfirm({ open: true, id: id });
    }

    const openSystemComponentFormDialog = (systemComponent) => {
        setSystemComponentFormDialog({ open: true, systemComponent: systemComponent, systemInfoId: systemComponent.systemInfoId });
    }

    const onSystemComponentFormClose = () => {
        setSystemComponentFormDialog(DEFAULT_COMPONENT_DIALOG_STATE);
    }

    return (
        <Box sx={{ p: { md: 2 } }}>
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Components" breadcrumbs={breadcrumbs} icon={iconSet.component} />
                </Box>

                <Box className="heading-right-btn">
                    {canEditSystemComponent && (
                        <Box>
                            <Button sx={{ mr: 1 }} variant="contained" onClick={onCreateClick}>
                                Add Component
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <Card>
                <CardContent>
                    {loading && (<CircleLoading />)}

                    {!loading && (
                        <Table
                            COLUMNS={COLUMNS}
                            parseData={systemComponents}
                            hideColumn={hideColumn}
                            heading={"Components"}
                        />
                    )}
                </CardContent>
            </Card>

            <ConfirmDialog
                open={deleteConfirm.open}
                title="Delete Component"
                message="Are you sure you want to delete this component? This cannot be undone."
                onClose={closeDeleteConfirm}
                maxWidth="xs"
            />

            <SystemComponentFormDialog
                open={systemComponentFormDialog.open}
                onClose={onSystemComponentFormClose}
                systemComponent={systemComponentFormDialog.systemComponent}
                systemInfoId={systemComponentFormDialog.systemInfoId}
                requireNewComponent={systemComponentFormDialog.requireNewComponent}
            />
        </Box>
    );
};

