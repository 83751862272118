import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

// ----------------------------------------------------------------------

  // Example CSS gradient
  // const gradientCSS = `linear-gradient(to top,
  //  #440253 0%,
  //  #070581 12.5%,
  //  #135AFB 25%,
  //  #20F1ED 37.5%,
  //  #00FF21 50%,
  //  #F8F132 62.5%,
  //  #F7561A 75%,
  //  #AB000B 87.5%,
  //  #AB000B 100%);`;

// ----------------------------------------------------------------------

const MapLegend = (props) => {
  const { label, colorRange, rangeUnits } = props;
  const [gradientCss, setGradientCss] = useState('');
  const [labels, setLabels] = useState([]);

  const createGradient = (colorRange) => {
    if (!colorRange || colorRange.length === 0) return '';

    // Maplibre gradient alternates the value and color in an array
    const rangeValues = colorRange.filter((_, i) => i % 2 === 0);
    const rangeColors = colorRange.filter((_, i) => i % 2 !== 0);

    const range = rangeValues[rangeValues.length - 1] - rangeValues[0];

    const gradientItems = [];
    const labelItems = [];

    for (let i = 0; i < rangeValues.length; i++) {
      const percent = (rangeValues[i] - rangeValues[0]) / range * 100;
      const color = rangeColors[i];

      gradientItems.push(`${color} ${percent}%`);

      // used to align the labels with the color range
      let translateY = '50%';

      if (i === 0) {
        translateY = '15%';
      } else if (i === rangeValues.length - 1) {
        translateY = '85%';
      }

      labelItems.push({
        value: rangeValues[i],
        percent,
        translateY
      });
    }

    let gradient = 'linear-gradient(to top, ';
    gradient += gradientItems.join(', ');
    gradient += ');';

    return { gradient, labelItems };
  }

  useEffect(() => {
    if (!colorRange || colorRange.length === 0) return '';

    const { gradient, labelItems } = createGradient(colorRange);
    setGradientCss(gradient);
    setLabels(labelItems);
  }, [colorRange]);

  return (
    <Box
      sx={{
        p: 1,
        background: '#ffffff7d',
        borderRadius: '3px',
        border: '1px solid #a1a1a1',
        display: 'flex',
        flexDirection: 'column',
        height: '70%',
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 1, textAlign: 'left', width: '80px', fontWeight: 'bold', fontSize: '0.8rem', color: '#333' }}>
        <div>{label}</div> {rangeUnits && (<div><small>{rangeUnits}</small></div>)} 
      </Typography>
      <Box sx={{ flex: 1, display: 'flex' }}>
        <Box sx={{
          width: '20px',
          mr: 1,
          borderRadius: '3px',
          background: gradientCss,
          border: '1px solid #ccc',
          padding: '4px'
        }} />
        <Box sx={{ position: 'relative', width: '100%' }}>
          <Box sx={{
            position: 'absolute',
            left: '0',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
            {labels.map((label, index) => (
              <Typography
                key={index}
                variant="body2"
                sx={{
                  position: 'absolute',
                  bottom: `${label.percent}%`,
                  transform: `translateY(${label.translateY})`,
                  fontSize: '0.7rem'
                }}>
                {label.value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MapLegend;
