import React, { useState, useEffect, useRef } from "react";
import {
    useTable,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    useFilters,
} from "react-table";
import { message } from "src/@core/data/message";
import "src/custom.css";

import {
    Box,
    Typography,
    TableContainer,
    Table as TableMui,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
    Fade,
    TablePagination,
    Paper,
} from '@mui/material';

const Table = (params) => {
    const { COLUMNS, parseData, hideColumn, heading, getTooltipContent, tableWrapStyle, hidePagination } = params;
    const columns = React.useMemo(() => COLUMNS, [COLUMNS]);
    const data = React.useMemo(() => parseData, [parseData]);
    const initialState = { hiddenColumns: hideColumn };

    const [tableHeight, setTableHeight] = useState(0);
    const tableRef = useRef(null);

    const updateTableHeight = () => {
        const windowHeight = window.innerHeight;
        // const tableDistanceFromTopOfWindow = 420;
        const offset = 70;
        const tableDistanceFromTopOfWindow = tableRef.current.getBoundingClientRect().top + offset;
        setTableHeight(windowHeight - tableDistanceFromTopOfWindow);
    };

    useEffect(() => {
        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);
        return () => {
            window.removeEventListener('resize', updateTableHeight);
        };
    }, []);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            //initialState: { pageIndex: 0 },
            initialState,
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        usePagination
    );

    // Render the UI for your table
    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box flexGrow={1} p={2}>
                    <Typography variant="h5" component="h5" style={{ paddingRight: 8 }}>
                        {heading}
                    </Typography>
                </Box>
                <Box p={3} pr={0}>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </Box>
            </Box>

                <TableContainer 
                    style={{ ...tableWrapStyle }}
                    ref={tableRef}
                    sx={{
                        maxHeight: tableHeight,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        borderRadius: '4px',
                        boxShadow: ' 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <TableMui stickyHeader {...getTableProps()}>
                        <TableHead>
                            {headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <TableCell
                                            {...column.getHeaderProps({
                                                className: column.collapse ? 'collapse' : '',
                                            })}
                                            sx={{
                                                fontWeight: 600,
                                            }}
                                        >
                                            {column.render('Header')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <Tooltip
                                        key={i}
                                        TransitionComponent={Fade}
                                        placement="bottom-start"
                                        title={
                                            getTooltipContent
                                                ? getTooltipContent(parseData[i], hideColumn)
                                                : '-'
                                        }
                                        disableHoverListener={!getTooltipContent}
                                    >
                                        <TableRow
                                            {...row.getRowProps()}
                                            hover
                                        >
                                            {row.cells.map((cell) => (
                                                <TableCell
                                                    {...cell.getCellProps({
                                                        className: cell.column.collapse ? 'collapse' : '',
                                                    })}
                                                >
                                                    {cell.render('Cell')}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </Tooltip>
                                );
                            })}
                        </TableBody>
                    </TableMui>

                    {(!data || data.length === 0) && (
                        <Typography color="textSecondary" sx={{ ml: 2, mt: 2 }}>{message.no_data}</Typography>
                    )}

                    {(hidePagination === undefined || !hidePagination) && (
                        <TablePagination
                            component="div"
                            count={data.length}
                            page={pageIndex}
                            onPageChange={(event, newPage) => gotoPage(newPage)}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={(event) => setPageSize(Number(event.target.value))}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            sx={{
                                '& .MuiTablePagination-displayedRows': {
                                  marginBottom: 0
                                },
                                '& .MuiTablePagination-selectLabel': {
                                    marginBottom: 0
                                }
                            }}
                        />
                    )}
                </TableContainer>
        </Box>
    );
};

export default Table;

function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);

    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 500);

    return (
        <section className="d-flex">
            <label className="form-label p-2 fw-bold m-0">Search</label>
            <input
                value={value || ""}
                className="form-control"
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`Search all ${count} records...`}
                style={{
                    fontSize: "1.1rem",
                }}
            />
        </section>
    );
}

function DropdownFilter({
    column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
    // Calculate the options for filtering
    // using the rows and column id
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a select dropdown for filtering
    return (
        <select
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
        >
            <option value="">All</option>
            {options.map(option => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}
