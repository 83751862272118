import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    systemComponents: [],
    unassignedSystemComponents: [],
    createdSystemComponent: {},
    systemComponentById: {},
    error: '',
    loading: false,
    loadingUnassigned: false,
};

export const getAllSystemComponent = createAsyncThunk(
    'systemComponent/getAllSystemComponent',
    async () => {
        try {
            const url = `/api/v2/system-components`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getUnassignedSystemComponents = createAsyncThunk(
    'systemComponent/getUnassignedSystemComponents',
    async () => {
        try{
            const url = `/api/v2/system-components/unassigned`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const createSystemComponent = createAsyncThunk(
    'systemComponent/createSystemComponent',
    async (data) => {
        try {
            const response = await axiosApiInstance.post(`/api/v2/system-components`, data);
            return response;
        } catch (error) {
        ToastError(error.message);
        }
    }
);

export const getSystemComponentById = createAsyncThunk(
    'systemComponent/getSystemComponentById',
    async (id) => {
        try {
            const url = `/api/v2/system-components/${id}`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const updateSystemComponent = createAsyncThunk(
    'systemComponent/updateSystemComponent',
    async (updateData) => {
        try {
            const { id, data } = updateData;
            const url = `/api/v2/system-components/${id}`;
            const response = await axiosApiInstance.put(url, data);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const deleteSystemComponent = createAsyncThunk(
    'systemComponent/deleteSystemComponent',
    async (id) => {
        try {
            const url = `/api/v2/system-components/${id}`;
            const response = await axiosApiInstance.delete(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const assignSystemComponentToSystemInfo = createAsyncThunk(
    'systemComponent/assignSystemComponentToSystemInfo',
    async (data) => {
        const { id, systemInfoId } = data;
        const url = `/api/v2/system-components/${id}/system/${systemInfoId}`;
        const response = await axiosApiInstance.put(url);
        return response;
    }
);

export const removeSystemComponent = createAsyncThunk(
    'systemComponent/removeSystemComponent',
    async (id) => {
        try {
            const response = await axiosApiInstance.put(`/api/v2/system-components/${id}/remove-system`);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const systemComponentSlice = createSlice({
    name: 'systemComponent',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllSystemComponent.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllSystemComponent.fulfilled, (state, action) => {
                state.loading = false;
                state.systemComponents = action.payload.data;
            })
            .addCase(getAllSystemComponent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getUnassignedSystemComponents.pending, (state) => {
                state.loadingUnassigned = true;
            })
            .addCase(getUnassignedSystemComponents.fulfilled, (state, action) => {
                state.loadingUnassigned = false;
                state.unassignedSystemComponents = action.payload.data;
            })
            .addCase(getUnassignedSystemComponents.rejected, (state, action) => {
                state.loadingUnassigned = false;
                state.error = action.error.message;
            })

            .addCase(createSystemComponent.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSystemComponent.fulfilled, (state, action) => {
                state.loading = false;
                state.createdSystemComponent = action.payload.data;
                state.systemComponents.push(action.payload.data);
            })
            .addCase(createSystemComponent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getSystemComponentById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSystemComponentById.fulfilled, (state, action) => {
                state.loading = false;
                state.systemComponentById = action.payload.data;
            })
            .addCase(getSystemComponentById.rejected, (state, action) => {
                state.loading = false;
                state.systemComponentById = {};
                state.error = action.error.message;
            })

            .addCase(updateSystemComponent.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSystemComponent.fulfilled, (state, action) => {
                state.loading = false;

                const updatedSystemComponent = action.payload.data;
                const index = state.systemComponents.findIndex((systemComponent) => systemComponent.id === updatedSystemComponent.id);

                if (index !== -1) {
                    state.systemComponents[index] = updatedSystemComponent;
                }
            })
            .addCase(updateSystemComponent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(deleteSystemComponent.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteSystemComponent.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteSystemComponent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(assignSystemComponentToSystemInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(assignSystemComponentToSystemInfo.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(assignSystemComponentToSystemInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(removeSystemComponent.pending, (state) => {
                state.loading = true;
            })
            .addCase(removeSystemComponent.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(removeSystemComponent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default systemComponentSlice.reducer;
