import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// MUI
import {
    Box,
    Card,
    CardContent,
    Typography,
    Divider,
    MenuList,
    MenuItem,
    ListItemText,
    Button,
    Menu,
    Tabs,
    Tab,
} from "@mui/material";
// redux
import { iconSet } from "../../@core/data/icons";
import { ToastSuccess, ToastError } from "../../components/Toast";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";
import SystemInfoInstallsTab from "./components/SystemInfoInstallsTab";
import SystemComponentInstallsTab from "./components/SystemComponentInstallsTab";

// ----------------------------------------------------------------------

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "/history", label: "History" },
];

// ----------------------------------------------------------------------

export default function InstallHistoryPage() {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const { equipmentById } = useSelector((state) => state.equipment);
    const { systemInfos } = useSelector((state) => state.systemInfo);
    const params = new URLSearchParams(window.location.search);
    const paramsId = params.get("id");
    const canEditEquipment = !!isSuperAdmin;
    const [tabValue, setTabValue] = useState(0);

    console.log('tabValue: ', tabValue);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box className="d-flex flex-column px-md-4 form-layout customer-form">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="History" breadcrumbs={breadcrumbs} icon={iconSet.history} />
                </Box>
            </Box>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="Systems" />
                        <Tab label="Components" />
                    </Tabs>
                </Box>

                {tabValue === 0 && (
                    <Box>
                        <Box sx={{ mt: 2 }}>
                            <Card>
                                <CardContent>
                                    <SystemInfoInstallsTab />
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                )}

                {tabValue === 1 && (
                    <Box>
                        <Box sx={{ mt: 2 }}>
                            <Card sx={{ mt: 2 }}>
                                <CardContent>
                                    <SystemComponentInstallsTab />
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const SystemInfoMenu = ({ handleMenuClick, systemInfos }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        handleMenuClick(option);
        handleClose();
    }

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleButtonClick}>
                Add System
            </Button>

            <Menu
                sx={{ width: 320 }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuList dense>
                    <MenuItem onClick={() => handleMenuItemClick('new')}>
                        <ListItemText>Add New System</ListItemText>
                    </MenuItem>


                    {systemInfos && systemInfos.length > 0 && (
                        <Divider />
                    )}

                    {systemInfos && systemInfos.map((systemInfo) => (
                        <MenuItem key={systemInfo.id} onClick={() => handleMenuItemClick(systemInfo.id)}>
                            <ListItemText>{systemInfo.name ? `${systemInfo.name} | ${systemInfo.systemId}` : '<No Name>'}</ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
}