import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
// MUI
import {
    Box, 
    Button, 
    TextField, 
    Grid,
    Divider,
    Typography,
} from "@mui/material";
// redux
import { 
    clearSystemInfoById, 
    getSystemInfoById, 
    createSystemInfo, 
    updateSystemInfo 
} from "src/features/systemInfoSlice";
import { removeSystemComponent } from "src/features/systemComponentSlice";
// components
import { ToastSuccess, ToastError } from "src/components/Toast";
import ConfirmDialog from "src/components/ConfirmDialog";
import SystemComponentFormDialog from "./SystemComponentFormDialog";
import SystemComponentDisplay from "./SystemComponentDisplay";
import LoadingButton from "src/components/LoadingButton";

// ----------------------------------------------------------------------

const defaultFormValues = {
    name: "",
    systemId: "",
};

// ----------------------------------------------------------------------

export default function EquipmentForm({ id = null }) {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { systemInfoById } = useSelector((state) => state.systemInfo);
    const [submitting, setSubmitting] = useState(false);
    const [systemComponentFormDialog, setSystemComponentFormDialog] = useState({ open: false, systemComponent: null, systemInfoId: null });
    const [removeSystemComponentConfirm, setRemoveSystemComponentConfirm] = useState({ open: false, systemComponentId: null });
    const canEditSystems = !!isSuperAdmin;

    // react hook form
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: defaultFormValues
    });

    useEffect(() => {
        if (!systemInfoById) {
            reset();
        } else {
            const formData = {
                name: systemInfoById.name || "",
                systemId: systemInfoById.systemId || "",
            };

            reset(formData);
        }
    }, [systemInfoById]);

    useEffect(() => {
        if (!id) {
            dispatch(clearSystemInfoById());
            reset();
        } else {
            dispatch(getSystemInfoById(id));
        }
    }, [dispatch, id]);

    const openSystemComponentFormDialog = (systemComponent) => {
        setSystemComponentFormDialog({ open: true, systemComponent: systemComponent, systemInfoId: systemInfoById.id });
    }

    const onSystemComponentFormClose = () => {
        setSystemComponentFormDialog({ open: false, systemComponent: null, systemInfoId: null });
        dispatch(getSystemInfoById(id));
    }

    const removeSystemComponentById = async (systemComponentId) => {
        dispatch(removeSystemComponent(systemComponentId)).then((res) => {
            if (res.payload) {
                ToastSuccess("System Component removed!");
                dispatch(getSystemInfoById(id));
            } else {
                ToastError("Error removing system component");
            }
        });
    };

    const confirmRemoveSystemComponent = (systemComponent) => {
        setRemoveSystemComponentConfirm({ open: true, systemComponentId: systemComponent.id });
    }

    const closeRemoveSystemComponentConfirm = (confirm) => {
        if (confirm) {
            removeSystemComponentById(removeSystemComponentConfirm.systemComponentId);
        }

        setRemoveSystemComponentConfirm({ open: false, systemComponentId: null });
    }

    const onSubmit = (data) => {
        setSubmitting(true);

        if (systemInfoById?.id) {
            const updateData = {
                id: systemInfoById.id,
                data: {
                    ...data,
                    equipmentSK: systemInfoById.equipmentSK,
                }
            };

            dispatch(updateSystemInfo(updateData)).then((res) => {
                ToastSuccess("System saved");
                setSubmitting(false);
            });
        } else {
            dispatch(createSystemInfo(data)).then((res) => {
                setSubmitting(false);
                ToastSuccess("System saved");

                const url = `/systems/form?id=${res.payload.data.id}`;
                navigate(url, { replace: true });
            });
        }
    }

    return (
        <Box>
            {canEditSystems && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Name" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Controller
                                name="systemId"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="System ID" />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={submitting}
                                sx={{ width: '110px' }}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Grid>
                </form>
            )}
            {!canEditSystems && systemInfoById && (
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="subtitle2" color="text.secondary">Name:</Typography>
                            <Typography variant="subtitle1">{systemInfoById.name || '-'}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="subtitle2" color="text.secondary">ID:</Typography>
                            <Typography variant="subtitle1">{systemInfoById.systemId || '-'}</Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            {!!systemInfoById?.id && (
                <Divider sx={{ mt: 2, mb: 2 }} />
            )}

            {!!systemInfoById.id && (
                <Box>
                    <Box>
                        {isSuperAdmin && (
                            <Button sx={{ width: '180px' }} onClick={() => openSystemComponentFormDialog(null)} variant="contained" color="primary">
                                Add Component
                            </Button>
                        )}

                        {(!systemInfoById?.systemComponents || systemInfoById?.systemComponents.length === 0) && (
                            <Typography sx={{ mt: 2 }} variant="subtitle1" gutterBottom>No system components found</Typography>
                        )}

                        {(systemInfoById && systemInfoById?.systemComponents) && (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
                                {systemInfoById?.systemComponents.map((systemComponent, index) => (
                                    <Box key={index} sx={{ mr: 2, mb: 2 }}>
                                        <SystemComponentDisplay systemComponent={systemComponent} onEdit={openSystemComponentFormDialog} onRemove={confirmRemoveSystemComponent} />
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            <SystemComponentFormDialog
                open={systemComponentFormDialog.open}
                onClose={onSystemComponentFormClose}
                systemComponent={systemComponentFormDialog.systemComponent}
                systemInfoId={systemComponentFormDialog.systemInfoId}
            />

            <ConfirmDialog
                open={removeSystemComponentConfirm.open}
                title="Remove System Component"
                message="Are you sure you want to remove this system component?"
                onClose={closeRemoveSystemComponentConfirm}
                maxWidth="xs"
            />
        </Box >
    );
};

