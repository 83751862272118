// boxSelect.js
import mapboxgl from 'mapbox-gl';

/**
 * Adds a box-select (shift + drag) feature to highlight features on a given map.
 * 
 * @param {mapboxgl.Map} map - A Mapbox GL JS Map instance.
 */
export default function addBoxSelectToMap(map, onFinish) {
  // If the map is not yet loaded, wait for 'load' event before configuring
  if (!map.loaded()) {
    map.once('load', () => initialize(map, onFinish));
  } else {
    initialize(map, onFinish);
  }
}

/**
 * Initializes the necessary sources, layers, and event handlers
 * once the map is ready.
 * 
 * @param {mapboxgl.Map} map - A Mapbox GL JS Map instance that has loaded.
 */
function initialize(map, onFinish) {
  // Disable default box zooming.
  map.boxZoom.disable();

  const canvas = map.getCanvasContainer();
  let start;
  let current;
  let box;

  // Attach mousedown listener for shift + drag
  canvas.addEventListener('mousedown', mouseDown, true);

  /**
   * Mousedown handler; begins the box-draw process.
   */
  function mouseDown(e) {
    // Only continue if the shift key is pressed and left mouse button is used
    if (!(e.shiftKey && e.button === 0)) return;

    // Disable map drag panning
    map.dragPan.disable();

    // Call functions for the following events
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('keydown', onKeyDown);

    // Record the start point
    start = mousePos(e);
  }

  /**
   * Mouse move handler; draws or updates the selection box.
   */
  function onMouseMove(e) {
    current = mousePos(e);

    if (!box) {
      box = document.createElement('div');
      box.classList.add('boxdraw');
      canvas.appendChild(box);
    }

    const minX = Math.min(start.x, current.x);
    const maxX = Math.max(start.x, current.x);
    const minY = Math.min(start.y, current.y);
    const maxY = Math.max(start.y, current.y);

    // Update the transform/size of the selection box
    box.style.transform = `translate(${minX}px, ${minY}px)`;
    box.style.width = `${maxX - minX}px`;
    box.style.height = `${maxY - minY}px`;
  }

  /**
   * Mouse up handler; finalizes the bounding box selection.
   */
  function onMouseUp(e) {
    finish([start, mousePos(e)]);
  }

  /**
   * Key down handler; allows ESC to cancel selection.
   */
  function onKeyDown(e) {
    // If ESC is pressed
    if (e.keyCode === 27) finish();
  }

  /**
   * Finalize the selection or cancel if no bounding box is passed.
   */
  function finish(bbox) {
    // Remove event listeners
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
    document.removeEventListener('keydown', onKeyDown);

    if (box) {
      box.parentNode.removeChild(box);
      box = null;
    }

    // If a custom onFinish callback was provided, invoke it
    if (typeof onFinish === 'function') {
        onFinish(bbox);
    }

    // Re-enable map drag panning
    map.dragPan.enable();
  }

  /**
   * Returns a {x, y} object of the mouse position relative to the map canvas.
   */
  function mousePos(e) {
    const rect = canvas.getBoundingClientRect();
    return new mapboxgl.Point(
      e.clientX - rect.left - canvas.clientLeft,
      e.clientY - rect.top - canvas.clientTop
    );
  }
}
