import React from "react";
import { useRoutes } from 'react-router-dom';

// components
import HomePage from "../pages/home/HomePage";

import CompanyFormPage from "../pages/company/CompanyFormPage";
import CompanyPage from "../pages/company/CompanyPage";

import CustomerPage from "../pages/customer/CustomerPage";

import SystemComponentPage from "../pages/system-component/SystemComponentPage";

import EquipmentPage from "../pages/equipment/EquipmentPage";
import EquipmentFormPage from "../pages/equipment/EquipmentFormPage";

import SystemInfoPage from "../pages/system-info/SystemInfoPage";
import SystemInfoFormPage from "../pages/system-info/SystemInfoFormPage";

import ProjectPage from "../pages/project/ProjectPage";
import ProjectFormPage from "../pages/project/ProjectFormPage";
import DensityMapPage from "../pages/project/DensityMapPage";

import DataKeyPage from "../pages/data-key/DataKeyPage";

import AccountPage from "../pages/users/AccountPage";

import UserPage from "../pages/users/UserPage";
import RolePage from "../pages/users/role/RolePage";
import PermissionPage from "../pages/users/permission/PermissionPage";

import InstallHistoryPage from "../pages/install-history/InstallHistoryPage";

function RTDRoutes() {
    return useRoutes([
        { path: "/home", element: <HomePage /> },
        
        { path: "/companies", element: <CompanyPage /> },
        { path: "/companies/form", element: <CompanyFormPage /> },

        { path: "/customers", element: <CustomerPage /> },

        { path: "/components", element: <SystemComponentPage /> },

        { path: "/equipment", element: <EquipmentPage /> },
        { path: "/equipment/form", element: <EquipmentFormPage /> },
        
        { path: "/systems", element: <SystemInfoPage /> },
        { path: "/systems/form", element: <SystemInfoFormPage />},
        
        { path: "/projects", element: <ProjectPage /> },
        { path: "/projects/form", element: <ProjectFormPage /> },
        { path: "/projects/density-map", element: <DensityMapPage /> },
        { path: "/projects/:id/density-map", element: <DensityMapPage /> },
        
        { path: "/data-keys", element: <DataKeyPage /> },
        
        { path: "/account", element: <AccountPage /> },
        
        { path: "/users", element: <UserPage /> },
        { path: "/permissions", element: <PermissionPage /> },
        { path: "/roles", element: <RolePage /> },

        { path: "/history", element: <InstallHistoryPage /> },
    ].filter(Boolean));
}

export default RTDRoutes;
