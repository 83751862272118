import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ToastSuccess, ToastError } from "../components/Toast";
import { axiosApiInstance } from '../@core/utils/axios';

const initialState = {
    loading: false,
    error: '',
    systemInfoInstalls: [],
    equipmentSystemInfoInstalls: [],
    systemComponentInstalls: [],
    systemInfoComponentInstalls: [],
};

export const getAllSystemInfoInstalls = createAsyncThunk(
    'installHistory/getAllSystemInfoInstalls',
    async () => {
        try {
            const url = `/api/v2/install-history/system-info`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const getAllSystemComponentInstalls = createAsyncThunk(
    'installHistory/getAllSystemComponentInstalls',
    async () => {
        try {
            const url = `/api/v2/install-history/system-component`;
            const response = await axiosApiInstance.get(url);
            return response;
        } catch (error) {
            ToastError(error.message);
        }
    }
);

export const installHistorySlice = createSlice({
    name: 'installHistory',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAllSystemInfoInstalls.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllSystemInfoInstalls.fulfilled, (state, action) => {
                state.loading = false;
                state.systemInfoInstalls = action.payload.data;
            })
            .addCase(getAllSystemInfoInstalls.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getAllSystemComponentInstalls.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllSystemComponentInstalls.fulfilled, (state, action) => {
                state.loading = false;
                state.systemComponentInstalls = action.payload.data;
            })
            .addCase(getAllSystemComponentInstalls.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default installHistorySlice.reducer;
