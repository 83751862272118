export const PermissionOptions = {
    customer: "Customer",
    companypermission: "CompanyPermission",
    user: "User",
    project: "Project",
    equipment: "Equipment",
    roles: "CompanyRole",
}

export const permission =
{
    customer: "Customer",
    company: "Company",
    companypermission: "CompanyPermission",
    switchcompany: "SwitchCompany",
    user: "User",
    project: "Project",
    equipment: "Equipment",
    iot: "IOT",
    tablet: "Tablet",
    roles: "CompanyRole",
    permission: "CompanyPermission",
    system: 'system',
    component: 'Component',
    //make: "Make",
    //drum: "Drum",
    //status: "Status",
    //category: "Category",
    //model: "Model",
    //type: "Type"
}

export const PermissionTypes = {
    Customer: 'Customer',
    Company: 'Company',
    SwitchCompany: 'SwitchCompany',
    User: 'User',
    Project: 'Project',
    Equipment: 'Equipment',
    System: 'System',
    Iot: 'IOT',
    Tablet: 'Tablet',
    Roles: 'CompanyRole',
    Permission: 'CompanyPermission',
    Component: 'Component',
}