import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
// redux
import { getAllSystemInfo } from "src/features/systemInfoSlice";
// MUI
import {
    Box, Card, CardContent, Button
} from "@mui/material";
// components
import ActionMenu from "../../components/actionmenu";
import Table from "../../components/table/Table";
import { iconSet } from "../../@core/data/icons";
import { ToastSuccess, ToastError } from "../../components/Toast";
import { CircleLoading } from "../../components/loadingCircle";
import ConfirmDialog from "src/components/ConfirmDialog";
import PageBreadcrumbs from "src/components/PageBreadcrumbs";

// ----------------------------------------------------------------------

//let adminOptions = ["Edit", "Delete"]; // removing delete option until we have soft deletes
let adminOptions = ["Edit"];

const breadcrumbs = [
    { route: "/home", label: "Home" },
    { route: "", label: "Systems" },
];

const DEFAULT_COMPANY_NAME = "G4 Technologies"; // Just a label

// ----------------------------------------------------------------------

export default function SystemInfoPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const { loading, systemInfos } = useSelector((state) => state.systemInfo);
    const [deleteConfirm, setDeleteConfirm] = useState({ open: false, id: null });
    const canEditSystems = isSuperAdmin;

    let hideColumn = canEditSystems ? ['id', 'equipmentSK', 'companySK'] : ['id', 'equipmentSK', 'companySK', 'action'];

    const COLUMNS = [
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }) => {
                return canEditSystems ? (
                    <div>
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/systems/form?id=${row.values.id}`}
                        >
                            {row.values.name ? row.values.name : "-"}
                        </Link>
                    </div>
                ) : (
                    <div>
                        {row.values.name ? row.values.name : "-"}
                    </div>
                )
            }
        },
        {
            Header: "SystemId",
            accessor: "systemId",
            Cell: ({ row }) => {
                return (row.values.systemId ? row.values.systemId : "-");
            }
        },
        {
            Header: "Company",
            accessor: "companyName",
            Cell: ({ row }) => {
                return (canEditSystems && row.values.companyName) ? (
                    <div>
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/companies/form?rid=${row.values.companySK}`}
                        >
                            {row.values.companyName !== null ? row.values.companyName : "-"}
                        </Link>
                    </div>
                ) : (
                    <div>
                        {row.values.companyName !== null ? row.values.companyName : DEFAULT_COMPANY_NAME}
                    </div>
                )
            }
        },
        {
            Header: "Equipment ID",
            accessor: "equipmentID",
            Cell: ({ row }) => {
                return (canEditSystems && row.values.equipmentSK) ? (
                    <div>
                        <Link
                            style={{ textAlign: "left" }}
                            to={`/equipment/form?id=${row.values.equipmentSK}`}
                        >
                            {row.values.equipmentID !== null ? row.values.equipmentID : "-"}
                        </Link>
                    </div>
                ) : (
                    <div>
                        {row.values.equipmentID !== null ? row.values.equipmentID : "-"}
                    </div>
                )
            }
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: ({ row }) => {
                return row.values.status !== null ? row.values.status : "-";
            }
        },
        {
            Header: "Offset",
            accessor: "offset",
            Cell: ({ row }) => {
                return row.values.offset !== null ? row.values.offset : "-";
            }
        },
        {
            Header: "Id",
            accessor: "id",
            show: false,
        },
        {
            Header: "EquipmentSk",
            accessor: "equipmentSK",
            show: false,
        },
        {
            Header: "CompanySK",
            accessor: "companySK",
            show: false,
        },
        {
            Header: "Action",
            accessor: 'action',
            Cell: ({ row }) => (
                <Box>
                    {canEditSystems && (
                        <ActionMenu
                            key="action-menu"
                            options={adminOptions}
                            handleMenuClick={(option) =>
                                handleItemMenuClick(row.values, option)
                            }
                        />
                    )}
                </Box>
            ),
        },
    ];

    const onCreateClick = () => {
        navigate('/systems/form');
    };

    const onDeleteClick = (id) => {
        setDeleteConfirm({ open: true, id: id });
    }

    const closeDeleteConfirm = (confirm) => {
        if (confirm) {
            deleteEquipment(deleteConfirm.id);
        }

        setDeleteConfirm({ open: false, id: null });
    }

    useEffect(() => {
        if (isSuperAdmin && adminSelectedCompany?.companyId) {
            const data = { companyId: adminSelectedCompany.companyId };
            dispatch(getAllSystemInfo(data));
        } else {
            dispatch(getAllSystemInfo());
        }
    }, [dispatch, adminSelectedCompany]);

    const handleItemMenuClick = (values, option) => {
        if (option === "Edit") {
            navigate(`/systems/form?id=${values.id}`);
        } else if (option === "Delete") {
            onDeleteClick(values.id);
        }
    };

    return (
        <Box className="d-flex flex-column px-md-4 form-layout customer-form">
            <Box className="d-flex mb-2 mt-2">
                <Box className="flex-grow-1">
                    <PageBreadcrumbs pageName="Systems" breadcrumbs={breadcrumbs} icon={iconSet.system} />
                </Box>

                <Box className="heading-right-btn">
                    {canEditSystems && (
                        <Box>
                            <Button sx={{ mr: 1 }} variant="contained" onClick={onCreateClick}>
                                Add System
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
            <Card>
                <CardContent>
                    {loading && (<CircleLoading />)}

                    {!loading && (
                        <Table
                            COLUMNS={COLUMNS}
                            parseData={systemInfos}
                            hideColumn={hideColumn}
                            heading={"Systems"}
                        />
                    )}
                </CardContent>
            </Card>

            <ConfirmDialog
                open={deleteConfirm.open}
                title="Delete System"
                message="Are you sure you want to delete this System? This cannot be undone."
                onClose={closeDeleteConfirm}
                maxWidth="xs"
            />
        </Box>
    );
};

