import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// MUI
import {
    Box,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Avatar,
    Grid,
    IconButton,
    Collapse,
    Card,
    CardContent,
    CircularProgress,
  } from "@mui/material";
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// redux
import { getAllSystemComponentInstalls } from "src/features/installHistorySlice";

const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
}));

export default function SystemInfoInstallsTab() {
  const dispatch = useDispatch();
  const { loading, systemComponentInstalls } = useSelector((state) => state.installHistory);
  const [groupedData, setGroupedData] = useState([]);


  useEffect(() => {
    dispatch(getAllSystemComponentInstalls());
  }, [dispatch]);

  useEffect(() => {
    // Process data when systemInfoInstalls change
    if (systemComponentInstalls && systemComponentInstalls.length > 0) {
      // Group the data by systemComponentId
      const grouped = systemComponentInstalls.reduce((acc, install) => {
        const key = install.systemComponentId;
        if (!acc[key]) {
          acc[key] = {
            systemComponentId: key,
            systemComponent: install.systemComponent,
            installs: [],
          };
        }
        acc[key].installs.push(install);
        return acc;
      }, {});

      // For each group, sort the installs by date
      const getDateForSort = (install) => {
        if (install.removedOn) {
          return new Date(install.removedOn);
        } else if (install.installedOn) {
          return new Date(install.installedOn);
        } else {
          return new Date(0); // Earliest date possible
        }
      };

      const groupedArray = Object.values(grouped).map((group) => {
        group.installs.sort((a, b) => {
          const dateA = getDateForSort(a);
          const dateB = getDateForSort(b);
          return dateB - dateA; // descending order
        });
        return group;
      });

      setGroupedData(groupedArray);
    } else {
      setGroupedData([]);
    }
  }, [systemComponentInstalls]);

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ m: 0 }} gutterBottom>Component Installations</Typography>
        {loading && <CircularProgress size={20} sx={{ ml: 1 }} />}
      </Box>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <HeaderCell>Type</HeaderCell>
              <HeaderCell>Serial Number</HeaderCell>
              <HeaderCell>Make</HeaderCell>
              <HeaderCell>Model</HeaderCell>
              <HeaderCell>Number of Installs</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupedData.map((row) => (
              <Row key={row.systemComponentId} row={row} />
            ))}
            {!groupedData || groupedData.length === 0 && (
              <Typography color="textSecondary" sx={{ ml: 2, mt: 2 }}>No records found</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.systemComponent.type}</TableCell>
        <TableCell component="th" scope="row">{row.systemComponent.serialNumber}</TableCell>
        <TableCell>{row.systemComponent.make}</TableCell>
        <TableCell>{row.systemComponent.model}</TableCell>
        <TableCell>{row.installs.length}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <HistoryDetailsTable row={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const HistoryDetailsTable = ({ row }) => {
  return (
    <Card
      sx={{
        backgroundColor: 'background.paper',
        boxShadow: 'inset 0px 0px 4px rgba(0,0,0,0.2), inset -4px -4px 6px rgba(255,255,255,0.5)',
        borderRadius: 2,
        padding: 2,
        my: 1,
      }}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom component="div">
          Install History
        </Typography>
        <Table 
          size="small" 
          aria-label="install-details" 
          sx={{ 
            '& th': { whiteSpace: 'nowrap' } 
          }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell>System</HeaderCell>
              <HeaderCell>Installed On</HeaderCell>
              <HeaderCell>Installed By</HeaderCell>
              <HeaderCell>Removed On</HeaderCell>
              <HeaderCell>Removed By</HeaderCell>
              <HeaderCell>Status</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.installs.map((install) => (
              <TableRow key={install.id}>
                <TableCell>
                  {install.systemInfoName}
                </TableCell>
                <TableCell>
                  {install.installedOn
                    ? new Date(
                      install.installedOn
                    ).toLocaleDateString()
                    : "-"}
                </TableCell>
                <TableCell>
                  {install.installedByUser
                    ? `${install.installedByUser.firstName} ${install.installedByUser.lastName}`
                    : "-"}
                </TableCell>
                <TableCell>
                  {install.removedOn
                    ? new Date(
                      install.removedOn
                    ).toLocaleDateString()
                    : "-"}
                </TableCell>
                <TableCell>
                  {install.removedByUser
                    ? `${install.removedByUser.firstName} ${install.removedByUser.lastName}`
                    : "-"}
                </TableCell>
                <TableCell>
                  {install.removedOn ? "Inactive" : "Active"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

