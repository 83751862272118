// MUI
import {
    Box,
    Dialog, DialogTitle, DialogContent
} from '@mui/material';
// components
import SystemComponentForm from './SystemComponentForm';

// ----------------------------------------------------------------------

export default function SystemComponentFormDialog({ 
        open, 
        onClose, 
        systemComponent, 
        systemInfoId,
        requireNewComponent,
     }) {
    const onSaved = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle sx={{ mb: 1 }}>{!!systemComponent ? 'Update' : 'Add'} Component</DialogTitle>
            <DialogContent>
                <Box sx={{ pt: 1, minHeight: '120px' }}>
                    <SystemComponentForm systemComponent={systemComponent} onSaved={onSaved} systemInfoId={systemInfoId} requireNewComponent={requireNewComponent} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}
