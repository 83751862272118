import React from 'react';
import { Box, Typography, Paper, IconButton, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

const SystemComponentDisplay = ({ systemComponent, onEdit, onRemove }) => {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;

    return (
        <Box>
            <Paper elevation={1} sx={{ p: 2, minWidth: 300, maxWidth: 300 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" align="center">{systemComponent.type}</Typography>

                    {isSuperAdmin && (
                        <Box>
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={() => onEdit(systemComponent)}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                color="error"
                                size="small"
                                onClick={() => onRemove(systemComponent)}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    )}
                </Box>

                <Divider sx={{ my: 1 }} />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Type:</Typography>
                    <Typography variant="body2">{systemComponent.type}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Make:</Typography>
                    <Typography variant="body2">{systemComponent.make}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Typography variant="subtitle2">Model:</Typography>
                    <Typography variant="body2">{systemComponent.model}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle2">Serial Number:</Typography>
                    <Typography variant="body2">{systemComponent.serialNumber}</Typography>
                </Box>
            </Paper>
        </Box>
    );
};

export default SystemComponentDisplay;