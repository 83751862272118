import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllEquipment } from "src/features/equipmentSlice";
// MUI
import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
// components
import Table from "src/components/table/Table";
import { SystemStatusBadge } from "src/pages/project/components/ProjectComponents";
import { CircleLoading } from "src/components/loadingCircle";

// ----------------------------------------------------------------------

const TooltipLabel = styled(Box)(() => ({
    width: '60px'
}));

export default function ProjectsTable() {
    const loginRes = JSON.parse(localStorage.getItem("loginRes"));
    const isSuperAdmin = loginRes?.superAdmin;
    const dispatch = useDispatch();
    const { loading, allEquipment } = useSelector((state) => state.equipment);
    const { adminSelectedCompany } = useSelector((state) => state.company);
    const hideColumn = (!isSuperAdmin) ? ['companyName', 'equipmentSK'] : ['equipmentSK'];

    useEffect(() => {
        dispatch(getAllEquipment(loginRes));
    }, [adminSelectedCompany]);

    const COLUMNS = [
        {
            Header: "ID",
            accessor: "equipmentID",
            Cell: ({ row }) => (
                <div>
                    <Link
                        style={{ textAlign: "left" }}
                        to={`/equipment/form?id=${row.values.equipmentSK}`}
                    >
                        {row.values.equipmentID}
                    </Link>
                </div>
            ),
        },
        {
            Header: "Company",
            accessor: "companyName",
        },
        {
            Header: "Project",
            accessor: "projectName",
        },
        {
            Header: "Status",
            accessor: "systemStatus",
            Cell: ({ row }) => {
                if (row.values.systemStatus !== null) {
                    return <SystemStatusBadge value={row.values.systemStatus} sx={{ position: 'relative', height: '12px', width: '12px' }}></SystemStatusBadge>
                } else {
                    return "-";
                }
            }
        },
        {
            Header: "Model",
            accessor: "model",
        },
        {
            Header: "Equipment SK",
            accessor: "equipmentSK",
            show: false,
        },
    ];

    const getTooltipContent = (data, tooltopHideColumn) => {
        return (
            <Box>
                <Box className="d-flex flex-row">
                    <TooltipLabel>ID:</TooltipLabel> {data.equipmentID}
                </Box>
                {tooltopHideColumn.includes("companyName") && (
                    <Box className="d-flex flex-row">
                        <TooltipLabel>Company:</TooltipLabel> {data.companyName}
                    </Box>
                )}
                {tooltopHideColumn.includes("projectName") && (
                    <Box className="d-flex flex-row">
                        <TooltipLabel>Project:</TooltipLabel> {data.projectName}
                    </Box>
                )}
                <Box className="d-flex flex-row">
                    <TooltipLabel>Make:</TooltipLabel> {data.manufacturer ? data.manufacturer : "-"}
                </Box>
                <Box className="d-flex flex-row">
                    <TooltipLabel>Model:</TooltipLabel> {data.model ? data.model : "-"}
                </Box>
                <Box className="d-flex flex-row">
                    <TooltipLabel>Drum Size:</TooltipLabel> {data.drumSize}
                </Box>
                <Box className="d-flex flex-row">
                    <TooltipLabel>SK:</TooltipLabel> {data.equipmentSK}
                </Box>
            </Box >
        );
    };

    return (
        <>
            {loading && (
                <div>
                    <CircleLoading />
                </div>
            )}
            {(!loading && allEquipment) && (
                <Table
                    COLUMNS={COLUMNS}
                    parseData={allEquipment}
                    hideColumn={hideColumn}
                    heading={"Equipment"}
                    getTooltipContent={getTooltipContent}
                    tableWrapStyle={{ overflowX: 'hidden' }}
                />
            )}
        </>
    );
}
