import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// forms
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// MUI
import {
    Grid,
    TextField,
    Box,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    Divider,
    MenuList,
    ListItemText,
    Button,
    Menu,
} from "@mui/material";
// redux
import { getSystemInfoById } from "src/features/systemInfoSlice";
import {
    getUnassignedSystemComponents,
    createSystemComponent,
    updateSystemComponent,
    assignSystemComponentToSystemInfo,
} from "src/features/systemComponentSlice";
// components
import { ToastSuccess } from 'src/components/Toast';
import LoadingButton from 'src/components/LoadingButton';

// ----------------------------------------------------------------------

const defaultFormValues = {
    type: '',
    make: '',
    model: '',
    serialNumber: '',
};

// ----------------------------------------------------------------------

export default function SystemComponentForm({ systemComponent, systemInfoId, onSaved, requireNewComponent=false }) {
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const { unassignedSystemComponents } = useSelector((state) => state.systemComponent);
    const [selectedTypeComponents, setSelectedTypeComponents] = useState([]);
    const [showNewForm, setShowNewForm] = useState(false);

    useEffect(() => {
        dispatch(getUnassignedSystemComponents());
    }, [dispatch]);

    const formSchema = Yup.object().shape({
        type: Yup.string().required("Type is required"),
        make: Yup.string().required("Make is required"),
        model: Yup.string().required("Model is required"),
        serialNumber: Yup.string().required("Serial Number is required"),
    });

    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        watch,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues: systemComponent || defaultFormValues
    });

    const selectedType = watch('type');

    useEffect(() => {
        const selectedTypeComponents = unassignedSystemComponents.filter(
            (component) => component.type === selectedType
        );
        setSelectedTypeComponents(selectedTypeComponents);
        setShowNewForm(false);
    }, [selectedType]);

    const onSubmit = (data) => {
        setSubmitting(true);

        if (systemComponent?.id) {
            const updateData = {
                id: systemComponent.id,
                data: {
                    ...data,
                }
            };

            dispatch(updateSystemComponent(updateData)).then((res) => {
                setSubmitting(false);
                ToastSuccess("Component Saved");
                onSaved();
            });
        } else {
            const createData = {
                ...data,
            };

            dispatch(createSystemComponent(createData)).then((res) => {
                setSubmitting(false);
                
                if (systemInfoId) {
                    assignSystemComponentById(res.payload.data.id);
                }else{
                    ToastSuccess("Component Saved");
                    onSaved();
                }
            });
        }
    }

    const assignSystemComponentById = async (systemComponentId) => {
        dispatch(assignSystemComponentToSystemInfo({ id: systemComponentId, systemInfoId: systemInfoId })).then((res) => {
            if (res.payload) {
                ToastSuccess("System component added!");
                dispatch(getSystemInfoById(systemInfoId));
                onSaved();
            } else {
                ToastError("Error adding system component");
            }
        });
    };

    const handleMenuClick = (option) => {
        if (option === 'new') {
            setShowNewForm(true);
        } else {
            assignSystemComponentById(option);
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth>
                                <InputLabel shrink>Type</InputLabel>
                                <Select
                                    value={value || ''}
                                    onChange={onChange}
                                    input={<OutlinedInput notched label="Type" />}
                                >
                                    <MenuItem value="IPC">IPC</MenuItem>
                                    <MenuItem value="Temperature Sensor">Temperature Sensor</MenuItem>
                                    <MenuItem value="Vibration Sensor">Vibration Sensor</MenuItem>
                                    <MenuItem value="GPS">GPS</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>

                {(selectedType !== '' && !systemComponent && !showNewForm && !requireNewComponent) && (
                    <Grid item sm={12}>
                        <SystemComponentMenu
                            handleMenuClick={handleMenuClick}
                            selectedType={selectedType}
                            systemComponents={selectedTypeComponents}
                        />
                    </Grid>
                )}

                {(systemComponent || showNewForm || requireNewComponent) && (
                    <>
                        <Grid item sm={12} md={6}>
                            <Controller
                                name="make"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Make" />
                                )}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Controller
                                name="model"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Model" />
                                )}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Controller
                                name="serialNumber"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField {...field} fullWidth error={!!error} helperText={error?.message} label="Serial Number" />
                                )}
                            />
                        </Grid>

                        <Grid item sm={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    loading={submitting}
                                    sx={{ width: '110px' }}
                                >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </>
                )}
            </Grid>
        </form>
    );
};



const SystemComponentMenu = ({ handleMenuClick, systemComponents, selectedType }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (option) => {
        handleMenuClick(option);
        handleClose();
    }

    return (
        <Box>
            <Button variant="contained" color="primary" onClick={handleButtonClick}>
                Add {selectedType}
            </Button>

            <Menu
                sx={{ width: 320 }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuList dense>
                    <MenuItem onClick={() => handleMenuItemClick('new')}>
                        <ListItemText>New {selectedType}</ListItemText>
                    </MenuItem>


                    {systemComponents && systemComponents.length > 0 && (
                        <Divider />
                    )}

                    {systemComponents && systemComponents.map((systemComponent) => (
                        <MenuItem key={systemComponent.id} onClick={() => handleMenuItemClick(systemComponent.id)}>
                            <ListItemText>
                                {systemComponent.make ? `${systemComponent.make}  ${systemComponent.model} | ${systemComponent.serialNumber}` : '<No Name>'}
                            </ListItemText>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
}